
import { interfaces } from "powerbi-visuals-utils-formattingutils";
import TextProperties = interfaces.TextProperties;
import { textMeasurementService } from "powerbi-visuals-utils-formattingutils";
import * as d3 from "../d3";

import * as styles from "./styles";
import { ColorScheme } from "./interfaces";
import { BoundingBox } from "@zebrabi/legacy-library-common/interfaces";
import { isIEorEdge } from "./helpers";

import {
    Scenario, ShowTotals, BorderType,
    WIDTH, HEIGHT, EMPTY, SETTINGS_ICON, P, RECT, LINE, CENTER, STROKE_WIDTH, STROKE, FONT_SIZE, FONT_FAMILY, FONT_STYLE, FONT_WEIGHT, ITALIC, TEXT, TEXT_ANCHOR, MIDDLE,
    CIRCLE, NORMAL, SEGOE_UI, AXIS, STROKE_DASHARRAY, PX, NEUTRAL, POSITIVE, NEGATIVE, MARKER, AXIS_SCENARIO_DELIMITER, GRAY, WHITE, PY, PL, FC, LEFT, START, END, FILL, X, Y,
    TITLE, BOLD, LABEL, HIGHLIGHT_COLOR, CX, CY, R, X1, Y1, X2, Y2, ID, OPACITY, TRANSFORM, OFFSET, STOP_COLOR, STOP_OPACITY, IN, SEGOE_UI_BOLD, PATH, D, ELLIPSE, RX, RY, FILL_OPACITY, DEFS, VarianceIcon, FONT_SIZE_UNIT, FILTER
} from "./constants";
import { DataPoint } from "../interfaces";

const SVG = "svg";
const G = "g";
const ICON_CIRCLE_ARROW_POSITIVE = "M20 10c0-5.522-4.476-10-10-10C4.479 0 0 4.479 0 10c0 5.524 4.478 10 10 10 5.524 0 10-4.476 10-10Zm-14.53.28a.75.75 0 0 1-.073-.976l.073-.084 4-4a.75.75 0 0 1 .977-.073l.085.072 4 4.002a.75.75 0 0 1-.977 1.133l-.084-.073-2.72-2.721v6.691a.75.75 0 0 1-.649.743l-.102.007a.75.75 0 0 1-.743-.648l-.007-.102v-6.69l-2.72 2.72a.75.75 0 0 1-.976.072l-.084-.072Z";
const ICON_CIRCLE_ARROW_NEGATIVE = "M-.001 9.999c0 5.523 4.477 10 10 10s10-4.477 10-10-4.477-10-10-10-10 4.477-10 10Zm14.53-.28a.75.75 0 0 1 .073.976l-.072.085-4.001 4a.75.75 0 0 1-.977.073l-.084-.073-4-4.001a.75.75 0 0 1 .977-1.133l.084.072 2.72 2.722V5.75a.75.75 0 0 1 .649-.744L9.999 5a.75.75 0 0 1 .743.648l.007.102v6.69l2.72-2.72a.75.75 0 0 1 .977-.073l.084.073Z";
const ICON_TRIANGLE_POSITIVE = "M10.0829 2L18 18H2L10.0829 2Z";
const ICON_TRIANGLE_NEGATIVE = "M9.9171 18L2 2L18 2L9.9171 18Z";

export function createSvgElement(container: HTMLElement, classed: string): d3.Selection<SVGElement, any, any, any> {
    const svg = d3.select(container)
        .append(SVG)
        .attr(WIDTH, 0)
        .attr(HEIGHT, 0);
    if (classed !== EMPTY) {
        svg.classed(classed, true);
    }
    return svg;
}

export function createGroupElement(container: d3.Selection<SVGElement, any, HTMLElement, any>, classed: string): d3.Selection<SVGElement, any, HTMLElement, any> {
    const group: d3.Selection<SVGElement, any, HTMLElement, any> = container.select(`.${classed.replace(/ /g, ".")}`);
    return group.empty() ? container.append(G).classed(classed, true) : group;
}

export function createIconGroup(chartId: number, parent: d3.Selection<SVGElement, any, any, any>): d3.Selection<SVGElement, any, any, any> {
    return createGroupElement(parent, `${SETTINGS_ICON}${chartId}`).classed(SETTINGS_ICON, true);
}

export function drawCircle(svg: d3.Selection<SVGElement, any, any, any>, className: string, cx: number, cy: number, r: number, color: string) {
    return svg.append(CIRCLE).classed(className, true)
        .attr(CX, cx)
        .attr(CY, cy)
        .attr(R, r)
        .attr(FILL, color)
        .attr(STROKE, color);
}

export function drawEllipse(svg: d3.Selection<SVGElement, any, any, any>, className: string, cx: number, cy: number, rx: number, ry: number, fillColor: string, fillOpacity: number, strokeColor: string, strokeWidth: number) {
    return svg.append(ELLIPSE).classed(className, true)
        .attr(CX, cx)
        .attr(CY, cy)
        .attr(RX, rx)
        .attr(RY, ry)
        .attr(FILL, fillColor)
        .attr(FILL_OPACITY, fillOpacity)
        .attr(STROKE, strokeColor)
        .attr(STROKE_WIDTH, strokeWidth);
}

export function drawLine(container: d3.Selection<SVGElement, any, any, any>, x1: number, x2: number, y1: number, y2: number, width: number, color: string, classed: string, dp?: DataPoint) {
    return container.append(LINE).data([dp]).classed(classed, true)
        .attr(X1, x1)
        .attr(Y1, y1)
        .attr(X2, x2)
        .attr(Y2, y2)
        .attr(STROKE_WIDTH, width)
        .attr(STROKE, color);
}

export function drawText(container: d3.Selection<SVGElement, any, any, any>, text: string, x: number, y: number, fontSize: number, color: string) {
    return container.append(TEXT)
        .text(text)
        .attr(X, Math.round(x))
        .attr(Y, Math.round(y + fontSize * 0.35))
        .attr(FILL, color)
        .style(FONT_SIZE, `${fontSize}${FONT_SIZE_UNIT}`)
        .style(TEXT_ANCHOR, MIDDLE);
}

export function getChevronArrowPath(width_full: number, height_full: number, isNegative: boolean, isVertical: boolean): string {
    const h_arrow = Math.max(Math.min(6, height_full - 4), 0);
    const h_rect = height_full - h_arrow;
    const w_arrow = Math.max(Math.min(6, width_full - 4), 0);
    const w_rect = width_full - w_arrow;
    const x_pad = (width_full / 3) / 2;
    const y_pad = (height_full / 3) / 2;

    if (isVertical) {
        return isNegative ?
            "M " + width_full + " " + y_pad +
            "L " + (width_full - w_rect) + " " + y_pad +
            "L 0 " + (y_pad + (height_full - (2 * y_pad)) / 2) +
            "L " + (width_full - w_rect) + " " + (height_full - y_pad) +
            "L " + width_full + " " + (height_full - y_pad) + " z"
            :
            "M 0 " + y_pad + "L " + w_rect + " " + y_pad +
            "L " + width_full + " " + (y_pad + (height_full - (2 * y_pad)) / 2) +
            "L " + w_rect + " " + (height_full - y_pad) + "L 0 " + (height_full - y_pad) + " z";
    }
    else {
        return isNegative ?
            "M " + x_pad + " " + 0 +
            "L " + (width_full - x_pad) + " " + 0 +
            "L " + (width_full - x_pad) + " " + h_rect +
            "L " + (width_full / 2) + " " + height_full +
            "L " + x_pad + " " + h_rect + " z"
            :
            "M " + x_pad + " " + h_arrow +
            "L " + (width_full / 2) + " 0 " +
            "L " + (width_full - x_pad) + " " + h_arrow +
            "L " + (width_full - x_pad) + " " + height_full +
            "L " + x_pad + " " + height_full + " z";
    }
}
export function drawVarianceIcon(container: d3.Selection<SVGElement, any, any, any>, varianceIcon: VarianceIcon, color: string, isNegative: boolean) {
    if (varianceIcon === VarianceIcon.Circle) {
        return (container.append(CIRCLE)
            .attr(CX, 10)
            .attr(CY, 10)
            .attr(R, 8))
            .attr(FILL, color);
    }
    return container.append(PATH)
        .attr(D, () => {
            if (varianceIcon === VarianceIcon.Triangle) {
                return isNegative ? ICON_TRIANGLE_NEGATIVE : ICON_TRIANGLE_POSITIVE;
            } else {
                return isNegative ? ICON_CIRCLE_ARROW_NEGATIVE : ICON_CIRCLE_ARROW_POSITIVE;
            }
        })
        .attr(FILL, color);
}

export function plotVerticalAxis(container: d3.Selection<SVGElement, any, any, any>, id: number, semanticAxis: boolean, x: number, y: number, height: number, color: string, referenceScenario: Scenario, showTotals: ShowTotals,
    yScale: d3.ScaleBand<string>, hasHierarchy: boolean, isGrandTotal: boolean, gap: number) {
    if (showTotals === ShowTotals.AboveHideValues && hasHierarchy && !isGrandTotal) {
        y += yScale.bandwidth() / (1 - gap);
        height -= yScale.bandwidth() / (1 - gap);
    }
    x = Math.round(x);
    y = Math.round(y);
    if (isNaN(x) || isNaN(y)) {
        return;
    }
    height = Math.round(height);
    if (semanticAxis) {
        if (referenceScenario === Scenario.Plan) {
            drawLine(container, x + 1, x + 1, y, y + height, 1, color, `${AXIS} t-${PL}`);
            drawLine(container, x - 1, x - 1, y, y + height, 1, color, `${AXIS} t-${PL}`);
        }
        else if (referenceScenario === Scenario.PreviousYear) {
            drawLine(container, x, x, y, y + height, 3, styles.getLighterColor(color), `${AXIS} t-${PY}`);
        }
        else if (referenceScenario === Scenario.Forecast) {
            const line = drawLine(container, x, x, y, y + height, 3, color, `${AXIS} t-${FC}`);
            line.style(STROKE_DASHARRAY, ("3,3"));
        }
        else if (referenceScenario === Scenario.Actual) {
            drawLine(container, x, x, y, y + height, 3, color, `${AXIS}`);
        }
        else {
            drawLine(container, x, x, y, y + height, 1, color, `${AXIS}`);
        }
    }
    else {
        drawLine(container, x, x, y, y + height, 1, color, `${AXIS}`);
    }
}

export function plotHorizontalAxis(container: d3.Selection<SVGElement, any, any, any>, semanticAxis: boolean, x: number, width: number, y: number, color: string, referenceScenario: Scenario) {
    x = Math.round(x);
    y = Math.round(y);
    width = Math.round(width);
    if (semanticAxis) {
        if (referenceScenario === Scenario.Plan) {
            drawLine(container, x, x + width, y - 1, y - 1, 1, color, AXIS);
            drawLine(container, x, x + width, y + 1, y + 1, 1, color, AXIS);
        }
        else if (referenceScenario === Scenario.PreviousYear) {
            drawLine(container, x, x + width, y, y, 3, styles.getLighterColor(color), AXIS);
        }
        else if (referenceScenario === Scenario.Forecast) {
            const line = drawLine(container, x, x + width, y, y, 3, color, AXIS);
            line.style(STROKE_DASHARRAY, ("3,3"));
        }
        else if (referenceScenario === Scenario.Actual) {
            drawLine(container, x, x + width, y, y, 3, color, AXIS);
        }
        else {
            drawLine(container, x, x + width, y, y, 1, color, AXIS);
        }
    }
    else {
        drawLine(container, x, x + width, y, y, 1, color, AXIS);
    }
}

export function plotAxisScenarioDelimiter(container: d3.Selection<SVGElement, any, any, any>, x: number, y1: number, y2: number) {
    drawLine(container, x, x, y1, y2, 1, GRAY, AXIS_SCENARIO_DELIMITER);
}

export function getLinearScale(minValue: number, maxValue: number, rangeStart: number, rangeEnd: number): d3.ScaleLinear<number, number> {
    const scaleLinear = d3.scaleLinear()
        .domain([minValue, maxValue])
        .rangeRound([rangeStart, rangeEnd]);

    return <d3.ScaleLinear<number, number>><unknown>(value => scaleLinear(value ?? 0));
}

export function getShapes(container: d3.Selection<SVGElement, any, HTMLElement, any>, id: string, classes: string, shapeType: string, dataPoints: any[]): d3.Selection<d3.BaseType, any, SVGElement, any> {
    let shapes = container
        .selectAll(id)
        .data(dataPoints);
    if (dataPoints.length > 0) {
        const enter = shapes.enter().append(shapeType)
            .classed(classes, true);
        shapes = shapes.merge(enter);
    }
    shapes.exit().remove();
    return shapes;
}

export function getLabels(barGroup: d3.Selection<SVGElement, any, any, any>, classed: string, dataPoints: any[]): d3.Selection<d3.BaseType, any, any, any> {
    let labels = barGroup
        .selectAll(`.${classed}`)
        .data(dataPoints);
    if (dataPoints.length > 0) {
        const text = labels.enter().append(TEXT).classed(classed, true).classed(LABEL, true);
        labels = labels.merge(text);
    }
    labels.exit().remove();
    return labels;
}

export function plotTitleParagraph(title: string, container: HTMLElement, alignment: string, fontSize: number, showTitle: boolean, color: string,
    fontFamily: string, topMargin: number = 0, animate: boolean = false): void {
    if (!showTitle) {
        return;
    }
    const titleElement = document.createElement(P);
    container.appendChild(titleElement);
    titleElement.textContent = title;
    titleElement.style.textAlign = alignment;
    titleElement.style.fontSize = fontSize + FONT_SIZE_UNIT;
    titleElement.style.fontFamily = fontFamily;
    titleElement.style.color = color;
    titleElement.style.marginTop = topMargin + PX;

    if (animate) {
        d3.select(titleElement).style(FONT_SIZE, 1 + FONT_SIZE_UNIT)
            .transition()
            .style(FONT_SIZE, fontSize + 4 + FONT_SIZE_UNIT)
            .transition()
            .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT);
    }
}

export function plotTitle(title: string, container: d3.Selection<SVGElement, any, any, any>, alignment: string, fontSize: number, showTitle: boolean, color: string, fontFamily: string, topMargin: number = 0, animate: boolean = false): number {
    if (!showTitle) {
        return 0;
    }
    let x: string;
    let anchor: string;
    if (alignment === LEFT) {
        anchor = START;
        x = "0";
    }
    else if (alignment === CENTER) {
        anchor = MIDDLE;
        x = "50%";
    }
    else {
        anchor = END;
        x = "100%";
    }
    let titleElement = container.selectAll(`.${TITLE}`);
    if (titleElement.empty()) {
        titleElement = container.append(TEXT).classed(TITLE, true);
    }

    titleElement.text(title)
        .attr(X, x)
        .attr(FILL, color)
        .attr(TEXT_ANCHOR, anchor)
        .style(FONT_SIZE, `${fontSize}${FONT_SIZE_UNIT}`)
        .style(FONT_FAMILY, fontFamily);
    let fontWeight = NORMAL;
    if (fontFamily === SEGOE_UI_BOLD) {
        fontWeight = BOLD;
        titleElement
            .style(FONT_FAMILY, SEGOE_UI)
            .style(FONT_WEIGHT, fontWeight);
    }

    const titleHeight = Math.round(measureTextHeight(title, fontSize, fontFamily, fontWeight, NORMAL));
    titleElement.attr(Y, Math.round(titleHeight / 2) + 2 + topMargin);

    if (animate) {
        titleElement.style(FONT_SIZE, 1 + FONT_SIZE_UNIT)
            .transition()
            .style(FONT_SIZE, fontSize + 4 + FONT_SIZE_UNIT)
            .transition()
            .style(FONT_SIZE, fontSize + FONT_SIZE_UNIT);
    }
    return titleHeight;
}

export function getBoundingBox(element: d3.Selection<SVGElement, any, any, any>): BoundingBox {
    let bb;
    element.each(function (d) { bb = (<SVGGraphicsElement>this).getBBox(); });
    return bb;
}

export function getBoundingBoxHtml(element: HTMLElement): DOMRect {
    return element.getBoundingClientRect();
}

export function centerTextVertically(yScale: d3.ScaleBand<string>, category: string, fontSize: number) {
    return Math.round(yScale(category) + yScale.bandwidth() / 2 + fontSize * 0.35);
}

export function measureTextWidth(text: string, fontSize: number, fontFamily: string, fontWeight: string, fontStyle: string): number {
    const textProperties = getTextProperties(text, fontSize, fontFamily, fontWeight, fontStyle);
    return textMeasurementService.measureSvgTextWidth(textProperties);
}

export function measureTextHeight(text: string, fontSize: number, fontFamily: string, fontWeight: string, fontStyle: string): number {
    const textProperties = getTextProperties(text, fontSize, fontFamily, fontWeight, fontStyle);
    return textMeasurementService.measureSvgTextHeight(textProperties);
}

export function getTailoredText(text: string, fontSize: number, fontFamily: string, fontWeight: string, fontStyle: string, width: number): string {
    const textProperties = getTextProperties(text, fontSize, fontFamily, fontWeight, fontStyle);
    return textMeasurementService.getTailoredTextOrDefault(textProperties, width);
}

export function getEstimatedTextHeight(text: string, fontSize: number, fontFamily: string, fontWeight: string, fontStyle: string): number {
    if (fontFamily === SEGOE_UI_BOLD) {
        fontFamily = SEGOE_UI;
        fontWeight = BOLD;
    }
    const textProperties = getTextProperties(text, fontSize, fontFamily, fontWeight, fontStyle);
    let textHeight = textMeasurementService.estimateSvgTextHeight(textProperties);

    // workaround: In some cases (when the DOM is not ready) the estimateSvgTextHeight wrongly returns 0. In this case we calculate the estimated height manually
    if (textHeight === 0 && fontSize > 0 && text?.length > 0) {
        textHeight = fontSize * 1.33 + 3;
    }

    return textHeight;
}

function getTextProperties(text: string, fontSize: number, fontFamily: string, fontWeight: string, fontStyle: string): TextProperties {
    return {
        text: text,
        fontSize: `${fontSize}${FONT_SIZE_UNIT}`,
        fontFamily: fontFamily,
        fontWeight: fontWeight,
        fontStyle: fontStyle,
    };
}

export function addPatternDefinitions(defs: d3.Selection<SVGElement, any, any, any>, scheme: ColorScheme, highlightedCategoriesCustomColors: object[]) {
    addpattern(defs, scheme.neutralColor, NEUTRAL);
    addpattern(defs, scheme.positiveColor, POSITIVE);
    addpattern(defs, scheme.negativeColor, NEGATIVE);
    addpattern(defs, scheme.markerColor, MARKER);
    addpattern(defs, scheme.highlightColor, HIGHLIGHT_COLOR);
    highlightedCategoriesCustomColors.forEach(customHighlightColor => {
        Object.keys(customHighlightColor).forEach(category => {
            addpattern(defs, customHighlightColor[category], customHighlightColor[category]);
        });
    });
    if (scheme.useCustomScenarioColors) {
        addpattern(defs, scheme.previousYearColor, PY);
        addpattern(defs, scheme.planColor, PL);
        addpattern(defs, scheme.forecastColor, FC);
    }
}

export function addColorsArrayPatternDefinitions(svg: d3.Selection<SVGElement, any, any, any>, colors: string[]) {
    const defs = svg.select<SVGElement>(DEFS);
    if (defs.empty() || !colors) {
        return;
    }
    colors.forEach(color => {
        addpattern(defs, color, color);
    });
}

function addpattern(element: d3.Selection<SVGElement, any, any, any>, color: string, identifier: string) {
    const pattern = element
        .append("pattern")
        .attr(ID, `diagonal-stripe-${identifier}`)
        .attr("patternUnits", "userSpaceOnUse")
        .attr(WIDTH, 4)
        .attr(HEIGHT, 4)
        .attr("patternTransform", "rotate(45)");
    pattern.append(RECT)
        .attr(FILL, WHITE)
        .attr(WIDTH, 4)
        .attr(HEIGHT, 4);
    pattern.append(RECT)
        .attr(WIDTH, 4)
        .attr(HEIGHT, 4)
        .attr(FILL, color)
        .attr(OPACITY, 0.1);
    pattern.append(RECT)
        .attr(WIDTH, 2)
        .attr(HEIGHT, 4)
        .attr(TRANSFORM, "translate(0,0)")
        .attr(FILL, color);
}

export function addBlurDefinitions(container: d3.Selection<SVGElement, any, any, any>) {
    container
        .append("filter")
        .attr(ID, "blur")
        .append("feGaussianBlur")
        .attr("stdDeviation", 2);
}

export function addGradientDefinitions(defs: d3.Selection<SVGElement, any, any, any>, id: string, color: string, scenario: Scenario,
    isReversed: boolean = false, isArea: boolean = false, verticalCharts: boolean = false) {
    if (scenario === Scenario.PreviousYear) {
        color = styles.getLighterColor(color);
    }
    const target1 = isReversed ? "100%" : "0%";
    const target2 = isReversed ? "0%" : "100%";

    const linearGradient = defs.append("linearGradient")
        .attr(ID, id);

    if (verticalCharts) {
        linearGradient
            .attr(X1, target2)
            .attr(Y1, "0%")
            .attr(X2, target1)
            .attr(Y2, "0%");
    }
    else {
        linearGradient
            .attr(X1, "0%")
            .attr(Y1, target1)
            .attr(X2, "0%")
            .attr(Y2, target2);
    }

    // Set the color for the start (0%)
    linearGradient.append("stop")
        .attr(OFFSET, isArea ? "70%" : "30%")
        .attr(STOP_COLOR, color);
    // Set the color and opacity for the end (100%)
    linearGradient.append("stop")
        .attr(OFFSET, "100%")
        .attr(STOP_COLOR, color)
        .attr(STOP_OPACITY, 0);
}

export function addOverlayGradientDefinition(
    defs: d3.Selection<SVGElement, any, any, any>,
    isReversed: boolean = false,
    verticalCharts: boolean = false
): void {
    const target1 = isReversed ? "100%" : "0%";
    const target2 = isReversed ? "0%" : "100%";

    const linearWhiteReverseGradient = defs.append("linearGradient")
        .attr(ID, "white-reverse-gradient" + (isReversed ? "_negative" : ""));

    if (verticalCharts) {
        linearWhiteReverseGradient
            .attr(X1, target1)
            .attr(Y1, "0%")
            .attr(X2, target2)
            .attr(Y2, "0%");
    }
    else {
        linearWhiteReverseGradient
            .attr(X1, "0%")
            .attr(Y1, target2)
            .attr(X2, "0%")
            .attr(Y2, target1);
    }

    linearWhiteReverseGradient.append("stop")
        .attr(OFFSET, "0%")
        .attr(STOP_COLOR, WHITE);
    linearWhiteReverseGradient.append("stop")
        .attr(OFFSET, "100%")
        .attr(STOP_COLOR, WHITE)
        .attr(STOP_OPACITY, 0);
}

export function addElasticDefinitions(defs: d3.Selection<SVGElement, any, any, any>) {
    const filter = defs.append("filter").attr(ID, "elastic");
    filter.append("feGaussianBlur")
        .attr(IN, "SourceGraphic")
        .attr("stdDeviation", 12)
        .attr("result", "blur");
    filter.append("feColorMatrix")
        .attr(IN, "blur")
        .attr("mode", "matrix")
        .attr("values", "1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 35 -15")
        .attr("result", "elastic");
    filter.append("feComposite")
        .attr(IN, "SourceGraphic")
        .attr("in2", "elastic")
        .attr("operator", "atop");
}

export function applyFontFamily(element: d3.Selection<HTMLElement, any, any, any>, fontFamily: string, bold: boolean, italic: boolean) {
    if (fontFamily === SEGOE_UI_BOLD || bold) {
        element.style(FONT_WEIGHT, BOLD);
    }
    if (fontFamily === SEGOE_UI_BOLD) {
        fontFamily = SEGOE_UI;
    }
    element.style(FONT_FAMILY, fontFamily);
    if (italic) {
        element.style(FONT_STYLE, ITALIC);
    }
}

export function drawBackgroundFill(chartArea: d3.Selection<SVGElement, any, any, any>, color: string, x: number, y: number, width: any, height: number) {
    if (color !== "") {
        let opacity = 1;
        if (isIEorEdge() && color.length === 9) { // expected hex color format: #rrggbbaa
            const alphaHex = color.substr(color.length - 2, 2);
            const alpha = parseInt(alphaHex, 16);
            opacity = alpha / 255;
            color = color.substr(0, color.length - 2);
        }
        return chartArea.append(RECT)
            .attr(OPACITY, opacity)
            .attr(X, x)
            .attr(Y, y)
            .attr(FILL, color)
            .attr(WIDTH, Math.ceil(width))
            .attr(HEIGHT, height);
    }
}

export function drawColorBorders(chartArea: d3.Selection<SVGElement, any, any, any>, color: string, x: number, y: number, width: number, height: number, drawingGT: boolean, borderType: BorderType) {
    if (color === "") {
        return;
    }
    const boundingGroup = chartArea.append(G);
    // Upper line
    if (borderType === BorderType.Header) {
        drawLine(boundingGroup, x, x + Math.ceil(width), y, y, 1, color, "border-upper");
    }
    let gtMargin = 1;
    if (borderType === BorderType.Body && drawingGT) {
        gtMargin = 0;
    }
    // Left line
    drawLine(boundingGroup, x, x, y, y + height - gtMargin, 1, color, "");
    // Right line
    drawLine(boundingGroup, x + Math.ceil(width), x + Math.ceil(width), y, y + height - gtMargin, 1, color, "border-right");
    // Lower line
    if (!drawingGT || borderType === BorderType.GrandTotal) {
        drawLine(boundingGroup, x, x + Math.ceil(width), y + height - gtMargin, y + height - gtMargin, 1, color, "");
    }
    return boundingGroup;
}

export function applyBlur(svgItemsToBlur: d3.Selection<SVGElement, any, any, any>[], htmlItemsToBlur: HTMLElement[]) {
    svgItemsToBlur.forEach(item => {
        if (item) {
            item
                .attr(FILTER, "url(#blur)")
                .attr(OPACITY, "0.4");

        }
    });
    htmlItemsToBlur.forEach(item => {
        if (item) {
            item.style.filter = "blur(2px)";
            item.style.opacity = "0.4";
        }
    });
}

export function removeBlur(blurredSvgItems: d3.Selection<SVGElement, any, any, any>[], blurredHtmlItems: HTMLElement[]) {
    d3.select(".trial-defs").remove();
    blurredSvgItems.forEach(item => {
        if (item) {
            item.attr(FILTER, null);
            item.attr(OPACITY, null);
        }
    });
    blurredHtmlItems.forEach(item => {
        if (item) {
            item.style.filter = null;
            item.style.opacity = null;
        }
    });
}
