
import { DataView, ViewMode, DataViewValueColumnGroup } from "@zebrabi/table-data";
import { ViewModel } from "./../interfaces";
import { ChartType, DifferenceHighlightFromTo, } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import * as viewModelHelpers from "./../library/viewModelHelpers";
import * as translations from "@zebrabi/legacy-library-common/translations";
import * as scenarios from "./../scenarios";
import * as viewModels from "./viewModels";
import { EMPTY, DifferenceLabel, DataLabelUnitOptions, ChartStyle } from "./../library/constants";
import * as styles from "./../library/styles";
import { ACTUAL, INTEGRATED, RESPONSIVE, WATERFALL } from "../consts";
import { getMeasureRole, MeasureRoles } from "@zebrabi/data-helpers/fieldAssignment";
import { Visual } from "../visual";

// eslint-disable-next-line max-lines-per-function
export function getViewModel(dataView: DataView, showCompanyStyle: boolean, locale: string, updateSettings?: ChartSettings): ViewModel {
    const view = dataView;

    const useAutoAssignedMeasureRoles = !updateSettings || dataView.metadata.columns.length !== updateSettings.usedMeasuresCount;

    if (!useAutoAssignedMeasureRoles) {
        // set roles from saved settings to take user modified roles into account
        const roles = [updateSettings.measure1Role, updateSettings.measure2Role, updateSettings.measure3Role, updateSettings.measure4Role, updateSettings.measure5Role];
        const usedRoles = [];

        dataView.metadata.columns.forEach((col, i) => {
            const role = roles[i];
            if (role && !usedRoles.includes(role)) {
                col.roles = {
                    [role]: true
                };
                col.type = role === MeasureRoles.Comments ? { "text": true } : { "numeric": true };

                usedRoles.push(role);
            }
        });
    }

    const scenarioOptions = scenarios.getScenarioOptionsExcel(dataView);

    const viewModel: ViewModel = {
        chartData: [],
        settings: new ChartSettings(showCompanyStyle, locale, scenarioOptions), // check if charts.ChartSettings type could be used
        title: EMPTY,
        valuesFieldName: null,
        isSingleValueViewModel: false,
        isSingleSeriesViewModel: false,
        isCategoryOnlyViewModel: false,
        isMultiples: false,
        is2dMultiples: false,
        isDateCategories: false,
    };

    // Apply the passed settings properties to current settings.
    if (updateSettings) {
        Object.keys(updateSettings).forEach(key => {
            if (key !== "scenarioOptions" && key !== "valueHeader" && key !== "referenceHeader" && key !== "locale"
                && key !== "relativeDifferenceHeader" && key != "absoluteDifferenceHeader" && key !== "secondReferenceHeader"
                && key !== "secondAbsoluteDifferenceHeader" && key !== "secondRelativeDifferenceHeader" && key !== "secondValueHeader"
                && key !== "multilineCategories") {
                viewModel.settings[key] = updateSettings[key];
            }
        });
    }

    if (useAutoAssignedMeasureRoles) {
        // set settings measure roles from auto-assigned roles from data view
        //console.log(dataView.metadata.measureRoles, dataView.metadata.columns)
        const dataViewRoles = dataView.metadata.measureRoles ? dataView.metadata.measureRoles : //[MeasureRoles.Values, MeasureRoles.PreviousYear, MeasureRoles.Plan, MeasureRoles.Forecast, MeasureRoles.Comments];
            dataView.metadata.columns.map(vs => getMeasureRole(vs.roles));

        dataViewRoles.forEach((role, i) => {
            if (i < 5) {
                viewModel.settings[`measure${i + 1}Role`] = role;
            }
        });
        //console.log(viewModel.settings.measure1Role)
        viewModel.settings.usedMeasuresCount = dataView.metadata.measureRoles ? dataView.metadata.measureRoles.length : dataView.metadata.columns.length;
    }

    const settings = viewModel.settings;

    const visualInstance = Visual.getInstance();
    let isOrganizationStyle = settings.chartStyle === ChartStyle.Company;
    if (isOrganizationStyle) {
        if (settings.selectedOrganizationStyleId != visualInstance.getOrganizationStyleSettings()?.id) {
            visualInstance.applyOrganizationStyleById(settings.selectedOrganizationStyleId);
        }
    } else if (settings.chartStyle !== ChartStyle.Custom) {
        settings.colorScheme = styles.getColorSchemeFromStyle(settings.chartStyle);
    }

    if (!isOrganizationStyle) {
        visualInstance.removeOrganizationStyle();
    }


    settings.showCommentBox = scenarioOptions.commentsIndices.length == 0 ? false : true;

    if (settings.scenarioOptions.secondReferenceScenario !== null && settings.switchReferenceScenarios) {
        scenarios.switchReferenceScenarios(settings.scenarioOptions);
    }

    if (!settings.differenceHighlightCustomFont) {
        settings.differenceHighlightFontFamily = settings.labelFontFamily;
        settings.differenceHighlightFontSize = settings.labelFontSize;
    }

    // settings.showGrandTotal = dataView.rowGrandTotal ? dataView.rowGrandTotal : false;*/
    if (isNoValuesDataView(view, settings)) {
        if (isCategoriesOnlyDataView(view, viewModel.settings)) {
            viewModel.isCategoryOnlyViewModel = true;
            // TODO - support case with only rows and no values..
            return viewModel; //viewModels.getCategoryViewModel(options.dataViews, viewModel, locale);
        }
        return viewModel;
    }

    const viewValues = view.values;
    const grouppedValues = viewValues.grouped();
    if (!grouppedValues) {
        return viewModel;
    }
    viewModel.valueSources = grouppedValues[0].values.map(v => v.source);

    scenarios.setCurrentSettingsScenarioHeaders(settings, scenarioOptions);
    settings.setNewScenarioLegendValues();
    const scenariosFromCapabilities: any = {
        actual: settings.actual,
        previousYear: settings.previousYear,
        forecast: settings.forecast,
        plan: settings.plan,
        "actual_previousYear": settings.actual_previousYear,
        "actual_previousYear_percent": settings.actual_previousYear_percent,
        "actual_plan": settings.actual_plan,
        "actual_plan_percent": settings.actual_plan_percent,
        "actual_forecast": settings.actual_forecast,
        "actual_forecast_percent": settings.actual_forecast_percent,
        "previousYear_plan": settings.previousYear_plan,
        "previousYear_plan_percent": settings.previousYear_plan_percent,
        "forecast_previousYear": settings.forecast_previousYear,
        "forecast_previousYear_percent": settings.forecast_previousYear_percent,
        "forecast_plan": settings.forecast_plan,
        "forecast_plan_percent": settings.forecast_plan_percent,

        "actual-previousYear": settings.actual_previousYear,
        "actual-previousYear-percent": settings.actual_previousYear_percent,
        "actual-plan": settings.actual_plan,
        "actual-plan-percent": settings.actual_plan_percent,
        "actual-forecast": settings.actual_forecast,
        "actual-forecast-percent": settings.actual_forecast_percent,
        "previousYear-plan": settings.previousYear_plan,
        "previousYear-plan-percent": settings.previousYear_plan_percent,
        "forecast-previousYear": settings.forecast_previousYear,
        "forecast-previousYear-percent": settings.forecast_previousYear_percent,
        "forecast-plan": settings.forecast_plan,
        "forecast-plan-percent": settings.forecast_plan_percent,
    };
    settings.defaultScenarioHeaders = { ...settings.defaultScenarioHeaders, ...scenariosFromCapabilities };

    settings.isPercentageData = settings.displayUnits === "P" || viewModelHelpers.isPercentageDataDataView(grouppedValues[0], 0);

    setFieldNamesAndTitle(viewModel, grouppedValues[0], settings, dataView, locale);

    // set valid chartLayout layout if needed
    if (settings.shouldPlotVerticalCharts()) {
        if (settings.chartType === ChartType.Waterfall && settings.chartLayout !== WATERFALL) {
            settings.chartLayout = WATERFALL;
        }
        else if (settings.chartType === ChartType.Variance && !(settings.chartLayout === INTEGRATED || settings.chartLayout === ACTUAL)) {
            settings.chartLayout = INTEGRATED;
        }
    }
    else if (settings.chartType === ChartType.Waterfall && settings.chartLayout !== WATERFALL
        || settings.chartType === ChartType.Variance && settings.chartLayout === WATERFALL) {
        settings.chartLayout = RESPONSIVE;
    }
    if (settings.chartType === ChartType.Area && settings.chartLayout !== ACTUAL) {
        settings.chartLayout = INTEGRATED;
    }

    if (viewValues.length === 1 && viewValues[0].values.length === 1 && (!view.categories || !view.categories[0].source)) {
        if (settings.chartType !== ChartType.Line && settings.chartType !== ChartType.Bar && settings.chartType !== ChartType.Advert) {
            settings.chartType = settings.isPercentageData ? ChartType.Line : ChartType.Bar;
        }
        viewModel.isSingleValueViewModel = true;
        // if (settings.viewMode === ViewMode.View && settings.isChartTypeLockedInViewMode(true) && settings.chartType !== ChartType.Advert) {
        //     settings.chartType = settings.getNextAvailableChartType(true, false);
        // }
        // TODO: Support this
        // return viewModels.getSingleValueViewModel(viewModel, view);
    }
    viewModel.isSingleValueViewModel = false;

    const hasCategoryHighlight = false;
    const hasSeriesHighlight = false;

    if (settings.scenarioOptions.valueScenario !== null && settings.scenarioOptions.referenceScenario === null && settings.scenarioOptions.secondValueScenario === null) {
        if (settings.chartType === ChartType.Variance) {
            settings.chartType = settings.isPercentageData ? ChartType.Line : ChartType.Bar;
        }
        if (settings.chartType === ChartType.Waterfall) {
            settings.varianceLabel = DifferenceLabel.Absolute;
        }
        viewModel.isSingleSeriesViewModel = true;

        // if (settings.viewMode === ViewMode.View && settings.isChartTypeLockedInViewMode(true) && settings.chartType !== ChartType.Advert) {
        //     settings.chartType = settings.getNextAvailableChartType(false, true);
        // }
        return viewModels.getSingleSeriesViewModel(viewModel, view, hasCategoryHighlight, hasSeriesHighlight, locale);
    }
    viewModel.isSingleSeriesViewModel = false;

    if (settings.chartType === ChartType.Advert) {
        return viewModel;
    }

    if (isDiffHighlightFromToInvalid(settings, viewModel)) {
        settings.differenceHighlightFromTo = DifferenceHighlightFromTo.Auto;
    }

    if (settings.chartType === ChartType.Bar && settings.isPercentageData) {
        settings.chartType = ChartType.Area;
    }

    // if (settings.viewMode === ViewMode.View && settings.isChartTypeLockedInViewMode(false)) {
    //     settings.chartType = settings.getNextAvailableChartType(false, false);
    // }

    if (scenarioOptions.referenceScenario === null && scenarioOptions.secondValueScenario !== null && (settings.chartType === ChartType.Waterfall || settings.chartType === ChartType.Bar)) {
        settings.chartType = ChartType.Variance;
        settings.chartLayout = ACTUAL;
    }

    if (settings.chartType === ChartType.Variance || settings.chartType === ChartType.Area || settings.chartType === ChartType.Line
        || settings.chartType === ChartType.PlusMinusDot || settings.chartType === ChartType.PlusMinus
        || (settings.stackedChart && grouppedValues.length > 1)) {
        return viewModels.getVarianceViewModel(viewModel, view, hasCategoryHighlight, hasSeriesHighlight, locale);
    }

    if (scenarioOptions.referenceScenario !== null) {
        if (settings.chartType !== ChartType.Waterfall) {
            settings.chartType = ChartType.Waterfall;
        }
        return viewModels.getWaterfallBridgeViewModel(viewModel, view, hasCategoryHighlight, hasSeriesHighlight, locale);
    }
    else {
        settings.chartType = ChartType.Bar;
        return viewModels.getSingleSeriesViewModel(viewModel, view, hasCategoryHighlight, hasSeriesHighlight, locale);
    }
}

function isDiffHighlightFromToInvalid(settings: ChartSettings, viewModel: ViewModel) {
    return settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.MinToMax &&
        (settings.differenceHighlightFromTo > 2 && viewModel.isSingleSeriesViewModel
            || settings.differenceHighlightFromTo > 3 && settings.scenarioOptions.secondValueScenario === null);
}

function setFieldNamesAndTitle(viewModel: ViewModel, groupValues: DataViewValueColumnGroup, settings: ChartSettings, dataView: DataView, locale: string) {
    let title = EMPTY;
    const viewValues = groupValues.values;
    viewModel.valuesFieldName = settings.scenarioOptions.valueIndex !== null ? viewValues[settings.scenarioOptions.valueIndex].source.displayName : null;

    if (settings.scenarioOptions.referenceIndex === null) {
        title = viewModel.valuesFieldName;
    }
    else {
        viewModel.referenceFieldName = viewValues[settings.scenarioOptions.referenceIndex].source.displayName;
        const andString = settings.scenarioOptions.secondActualValueIndex ? "," : " " + translations.getTranslatedAndString(locale);
        title = `${viewModel.valuesFieldName}${andString} ${viewModel.referenceFieldName}`;
    }

    if (settings.scenarioOptions.secondActualValueIndex) {
        settings.isDotChartPercentageData = viewModelHelpers.isPercentageDataDataView(groupValues, 1);
        viewModel.secondActualValuesFieldName = viewValues[settings.scenarioOptions.secondActualValueIndex].source.displayName;
        const andTranslation = translations.getTranslatedAndString(locale);
        title += ` ${andTranslation} ${viewModel.secondActualValuesFieldName}`;
    }

    title += viewModelHelpers.getCategoriesAndGroups(dataView, locale);

    if (settings.displayUnits !== "Auto" && settings.displayUnits !== "None" && settings.showUnits === DataLabelUnitOptions.Title) {
        let unit = settings.displayUnits;
        if (unit === "G") {
            unit = "bn";
        }
        if (unit === "P") {
            unit = "%";
        }
        const inTranslation = translations.getTranslatedInString(locale);
        title += ` (${inTranslation} ${unit})`;
    }

    // if (settings.scenarioOptions.tooltipsIndices && settings.scenarioOptions.tooltipsIndices.length > 0) {
    //     viewModel.tooltipsFieldNames = [];
    //     settings.scenarioOptions.tooltipsIndices.forEach(i => {
    //         viewModel.tooltipsFieldNames.push(viewValues[i].source.displayName);
    //     });
    // }

    if (settings.scenarioOptions.commentsIndices && settings.scenarioOptions.commentsIndices.length > 0) {
        viewModel.commentsFieldNames = [];
        settings.scenarioOptions.commentsIndices.forEach(i => {
            viewModel.commentsFieldNames.push(viewValues[i]?.source?.displayName || "Comment");
        });
    }

    viewModel.secondReferenceFieldName = settings.scenarioOptions.secondReferenceScenario !== null ? viewValues[settings.scenarioOptions.secondReferenceIndex].source.displayName : null;
    viewModel.secondSegmentValuesFieldName = settings.scenarioOptions.secondValueScenario !== null ? viewValues[settings.scenarioOptions.secondValueIndex].source.displayName : null;

    viewModel.title = title;
}

function isCategoriesOnlyDataView(dataView: DataView, settings: ChartSettings): boolean {
    return dataView
        && (!dataView.values || settings.scenarioOptions.valueIndex === null)
        && dataView.categories
        && dataView.categories.length > 0
        && dataView.categories[0].values !== null
        && dataView.categories[0].values.length > 0;
}

export function isNoValuesDataView(dataView: DataView, settings: ChartSettings): boolean {
    return !dataView
        || !dataView.values
        || !dataView.values[0]
        || !dataView.values[0].values
        || dataView.values[0].values.length === 0
        || settings.scenarioOptions.valueIndex === null;
    // || !dataView[0].categorical.values[0].values
    // || dataView[0].categorical.values[0].values.length === 0;
}
