
import { ChartData, DataPoint, DifferenceHighlightParameters } from "./../interfaces";
import { LabelProperties } from "./../library/interfaces";
import { ChartType, DifferenceHighlightFromTo, LabelDensity } from "./../enums";
import { ChartSettings } from "./../settings/chartSettings";
import { Visual } from "./../visual";
import { showPopupMessage } from "./../ui/ui";

import {
    Scenario, DifferenceLabel, FILL, HIGHLIGHTABLE, RECT, G, BAR, ITALIC, FONT_STYLE, PATTERNED, STROKE, LINE, NONE, CLICK, MOUSEOUT, MOUSEOVER, WIDTH, HEIGHT, Y, X, OPACITY, X1, Y1, X2, Y2, STROKE_WIDTH, POINTER_EVENTS, NORMAL, END, CATEGORY, TEXT, CLICKABLE
} from "./../library/constants";
import {
    CHART_CONTAINER, VALUE, START_POSITION, VALUE_GRADIENT, VALUE_GRADIENT_NEGATIVE, REFERENCE_GRADIENT, REFERENCE_GRADIENT_NEGATIVE,
    WHITE_REVERSE_GRADIENT, WHITE_REVERSE_GRADIENT_NEGATIVE, SECOND_VALUE_GRADIENT, SECOND_VALUE_GRADIENT_NEGATIVE, AXIS_BREAK_SYMBOL, HORIZONTAL_LABEL_PADDING,
} from "./../consts";

import * as drawing from "./../library/drawing";
import * as formatting from "./../library/formatting";
import * as styles from "./../library/styles";
import * as viewModels from "./../viewModel/viewModelHelperFunctions";
import * as charting from "./chart";
import * as d3 from "../d3";
import { getFontFamily, getFontWeight } from "./chart";
import { mapAdditionalLabelsWithOriginal } from "../helpers";

// eslint-disable-next-line max-lines-per-function
export default function waterfallChart(reportArea: d3.Selection<SVGElement, any, any, any>, svg: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement, x: number, y: number, height: number, width: number,
    chartData: ChartData, topMargin: number, bottomMargin: number, chartIndex: number, min: number, max: number, isSingleSeries: boolean, plotTitle: boolean, plotLegend: boolean) {
    const scenarioOptions = settings.scenarioOptions;
    let chartWidth = width;
    let chartXPos = x;
    if (plotLegend) {
        chartWidth -= settings.getLegendWidth();
        chartXPos += settings.getLegendWidth();
    }
    if (settings.differenceHighlight) {
        chartWidth -= settings.differenceHighlightWidth;
    }

    const yScale = charting.getYScale(min, max, height, topMargin, bottomMargin);
    const xScale = charting.getXScale(chartData, chartXPos, chartWidth, settings.getGapBetweenColumns());
    const xScaleLines = charting.getXScale(chartData, chartXPos, chartWidth, 0);
    const xScaleRangeBand = xScale.bandwidth();
    let xAxisPosition = !settings.hasAxisBreak ? 0 : chartData.axisBreak;

    const chartContainer = reportArea.insert(G, ":first-child").classed(CHART_CONTAINER, true);

    charting.plotChartTitle(chartContainer, settings, plotTitle, chartData, x, y, charting.getChartTitleWidth(chartWidth, settings), height, max, min, topMargin, bottomMargin);
    if (!settings.hasAxisBreak || chartData.axisBreak === 0) {
        xAxisPosition = y + yScale(0);
        drawing.plotHorizontalAxis(chartContainer, false, chartXPos, chartWidth, y + yScale(0), settings.colorScheme.axisColor, scenarioOptions.referenceScenario);
    }

    let bars = chartContainer
        .selectAll(`.${BAR}` + chartIndex)
        .data(chartData.dataPoints);
    const rect = bars.enter()
        .append(RECT)
        .classed(BAR, true)
        .classed(HIGHLIGHTABLE, dp => dp.isVariance)
        // Add a postfix to "category highlightable" class with the name of the topNCategoryOtherLabel in order to aim drawSingleOtherArrow function at specific category
        .classed(settings.topNOtherLabel, (d) => d.category === settings.topNOtherLabel && settings.showTopNCategories);
    bars = bars.merge(rect)
        .attr(WIDTH, xScaleRangeBand)
        .attr(HEIGHT, d => Math.abs(yScale(d.value) - yScale(0)))
        .attr(Y, d => y + yScale(d.startPosition))
        .attr(X, d => xScale(d.category));
    styles.applyToBars(bars.filter(b => !b.isVariance), (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.valueScenario, settings.chartStyle, false, settings.colorScheme);

    styles.applyToBars(bars.filter(b => b.isVariance), (d: DataPoint) => d.color, scenarioOptions.valueScenario, settings.chartStyle, true, settings.colorScheme);

    const secondSegmentValueBars = bars.filter((p) => p.secondSegmentValue !== null);
    const secondSegmentDataPoints = chartData.dataPoints.filter(dp => dp.secondSegmentValue !== null && dp.isVariance);
    if (secondSegmentDataPoints.length > 0) {
        styles.applyToBars(secondSegmentValueBars.filter(b => !b.isVariance), (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.secondValueScenario, settings.chartStyle, false, settings.colorScheme);
        styles.applyToBars(secondSegmentValueBars.filter(b => b.isVariance), (d: DataPoint) => d.color, scenarioOptions.secondValueScenario, settings.chartStyle, true, settings.colorScheme);
        drawing.plotAxisScenarioDelimiter(chartContainer, xScale(secondSegmentDataPoints[0].category) - xScaleRangeBand / 8, y + yScale(0) - 25, y + height);
    }

    const referenceSumBar = bars.filter((dp, i) => !dp.isVariance && i === 0);
    referenceSumBar.classed(PATTERNED, false);
    styles.applyToBars(referenceSumBar, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenarioOptions.referenceScenario, settings.chartStyle, false, settings.colorScheme);

    // Custom scenario notation
    const existingScenarioCategories = Object.keys(Object.assign({}, ...settings.scenarioCategories));
    existingScenarioCategories.map(c => {
        const bar = bars.filter(b => !b.isVariance && b.isCategoryResult && b.category === c);
        const scenario = settings.scenarioCategories ? settings.scenarioCategories[existingScenarioCategories.indexOf(c)][c] : scenarioOptions.valueScenario;
        styles.applyToBars(bar, (d: DataPoint) => settings.getValueDataPointColor(d, chartData.group), scenario, settings.chartStyle, false, settings.colorScheme);
    });

    if (settings.hasAxisBreak && chartData.axisBreak !== 0) {
        const isBreakNegative = chartData.axisBreak < 0;
        const whiteOverlayGradient = isBreakNegative ? WHITE_REVERSE_GRADIENT_NEGATIVE : WHITE_REVERSE_GRADIENT;
        addOverlayBar(chartContainer, chartData.dataPoints.filter(dp => !dp.isVariance), xScale, yScale, max, topMargin, y, whiteOverlayGradient);
    }

    let linesData = chartData.dataPoints.slice(0, chartData.dataPoints.length - 1);
    if (isSingleSeries) {
        linesData = linesData.filter((d, i) => !d.isVariance && chartData.dataPoints[i + 1].isVariance || chartData.dataPoints[i + 1].isVariance
            || Math.round(d.startPosition - (d.isNegative ? d.value : 0)) === Math.round(chartData.dataPoints[i + 1].startPosition));
    }
    else {
        linesData = linesData.filter((dp, i, arr) => { return i === arr.length - 1 || arr[i + 1].value !== null; });
    }
    let lines = chartContainer
        .selectAll(".line" + chartIndex)
        .data(linesData);
    const line = lines.enter()
        .append(LINE)
        .classed(LINE, true);
    lines = lines.merge(line)
        .attr(X1, d => xScale(d.category))
        .attr(Y1, d => y + (d.isNegative ? yScale(d.isVariance ? d.startPosition - d.value : d.value) : yScale(d.startPosition)))
        .attr(X2, d => xScale(d.category) + xScaleLines.bandwidth() + xScaleRangeBand)
        .attr(Y2, d => y + (d.isNegative ? yScale(d.isVariance ? d.startPosition - d.value : d.value) : yScale(d.startPosition)))
        .attr(STROKE_WIDTH, 1)
        .attr(STROKE, "grey");

    charting.plotCategories(chartContainer, settings, chartData, y + height, xScale, chartWidth, y + yScale(0), false, chartIndex);

    if (settings.differenceHighlight && chartData.dataPoints.length > 0) {
        const valueProperty: keyof DataPoint = isSingleSeries || settings.differenceHighlightFromTo > 0 ? START_POSITION : VALUE;

        //Subtotals difference highlight
        if (isSingleSeries && settings.showDifferenceHighlightSubtotals) {
            const differenceHighlightSubtotalsPairs = charting.getDiffHighlightSubtotalsDataPoints(settings.resultCategories, chartData.dataPoints);

            differenceHighlightSubtotalsPairs.forEach(pair => {
                if (!pair.fromDataPoint || !pair.toDataPoint) {
                    return;
                }
                const dhParams: DifferenceHighlightParameters = setDifferenceHighlightParameters(settings, pair.fromDataPoint, pair.toDataPoint, valueProperty, xScale, yScale, isSingleSeries, y, chartData.axisBreak);
                charting.addSubtotalDifferenceHighlight(chartContainer, settings, slider, xScaleRangeBand, dhParams.dhX1, dhParams.dhX2, dhParams.endValue, dhParams.startValue, dhParams.dhY1, dhParams.dhY2, xAxisPosition, chartData.isInverted, chartIndex, pair.toDataPoint);
            });
        }

        //General difference highlight
        let { fromDataPoint, toDataPoint } = charting.getDiffHighlightAutoDataPoints(chartData.dataPoints, ChartType.Waterfall, false);
        if (settings.differenceHighlightFromTo !== DifferenceHighlightFromTo.Auto) {
            if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.LastACToLastFC && secondSegmentDataPoints.length !== 0) {
                fromDataPoint = chartData.dataPoints[chartData.dataPoints.length - secondSegmentDataPoints.length - 2];
                toDataPoint = secondSegmentDataPoints[secondSegmentDataPoints.length - 1];
            }
            else {
                const diffHighlightDataPoints = settings.isSecondSegmentDiffHighlight() ? secondSegmentDataPoints : chartData.dataPoints.filter((p) => p.secondSegmentValue === null && p.isVariance);
                const fromToDataPoints = charting.getDiffHighlightDataPoints(diffHighlightDataPoints, settings.differenceHighlightFromTo, true);
                fromDataPoint = fromToDataPoints.fromDP;
                toDataPoint = fromToDataPoints.toDP;
            }
        }

        if (fromDataPoint && toDataPoint && toDataPoint[valueProperty] !== null && fromDataPoint[valueProperty] !== null) {
            const dhParams: DifferenceHighlightParameters = setDifferenceHighlightParameters(settings, fromDataPoint, toDataPoint, valueProperty, xScale, yScale, isSingleSeries, y, chartData.axisBreak);
            charting.addDifferenceHighlight(chartContainer, settings, slider, x + width, dhParams.dhX1, dhParams.dhX2, dhParams.endValue, dhParams.startValue, dhParams.dhY1, dhParams.dhY2, chartData.isInverted);
        }
    }

    const labelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, false, true);
    const varianceLabelsFormat = formatting.getPercentageFormatOrNull(settings.isPercentageData, settings.labelPercentagePointUnit, true, true);
    const hideUnits = settings.shouldHideDataLabelUnits();
    const labelsProperties = getWaterfallLabelProperties(chartData.dataPoints, settings, labelsFormat, varianceLabelsFormat, hideUnits, xScale, yScale, y, chartData.axisBreak);
    if (settings.showDataLabels) {
        if (labelsProperties.length > 0) {
            const varianceCalculationChange = !isSingleSeries && settings.getRealInteractionSettingValue(settings.allowVarianceCalculationChange);
            const varianceLabels = charting.plotLabels(chartContainer, `${chartIndex}`, labelsProperties, settings, NORMAL, varianceCalculationChange).filter(d => d.isVariance);
            let additionalLabels: d3.Selection<any, LabelProperties, any, any> = null;

            if (settings.varianceLabel === DifferenceLabel.Relative) {
                varianceLabels.style(FONT_STYLE, ITALIC);
            }
            else if (settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute) {
                const additionalVarianceLabelsProperties = getAdditionalLabelProperties(chartData.dataPoints, settings, xScale, yScale, y);
                mapAdditionalLabelsWithOriginal(additionalVarianceLabelsProperties, varianceLabels, settings.labelFontSize);
                additionalLabels = charting.plotLabels(chartContainer, `${chartIndex}_2`, additionalVarianceLabelsProperties, settings, ITALIC, varianceCalculationChange);
            }

            if (varianceCalculationChange && Visual.animateVarianceLabels) {
                charting.animateLabels(varianceLabels, additionalLabels, settings.labelFontSize);
            }
        }
    }

    if (settings.showCommentMarkers()) {
        const commentsDataPoint = chartData.dataPoints.filter(p => p.commentsMeasuresValues && p.commentsMeasuresValues.length > 0 && p.commentsMeasuresValues[0]);
        if (commentsDataPoint.length > 0) {
            plotCommentMarkers(chartContainer, commentsDataPoint, xScale, yScale, y, settings, labelsProperties);
        }
    }

    charting.addMouseHandlers(bars.filter(dp => dp.isVariance), svg);
    addWaterfallFixedColumnsMouseHandlers(bars.filter(dp => !dp.isVariance), chartContainer, settings, slider);

    let tooltipDataPoints = chartData.dataPoints;
    const effectiveAxisBreak = settings.hasAxisBreak && chartData.axisBreak !== null ? chartData.axisBreak : 0;
    if (settings.hasAxisBreak) {
        tooltipDataPoints = JSON.parse(JSON.stringify(chartData.dataPoints));
        tooltipDataPoints.filter(p => !p.isVariance).forEach(dp => dp.value = dp.value + chartData.axisBreak);
    }

    bars.exit()
        .remove();
    lines.exit()
        .remove();
}

function setDifferenceHighlightParameters(settings: ChartSettings, fromDataPoint: DataPoint, toDataPoint: DataPoint, valueProperty: keyof DataPoint, xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, isSingleSeries: boolean, y: number, axisBreakValue?: number): DifferenceHighlightParameters {
    let startValue: number = <number>fromDataPoint[valueProperty];
    let endValue: number = <number>toDataPoint[valueProperty];

    if (isSingleSeries && fromDataPoint.isNegative && fromDataPoint.startPosition + ((fromDataPoint.isVariance ? -1 : 1) * fromDataPoint.value) < 0) {
        startValue = fromDataPoint.startPosition + ((fromDataPoint.isVariance ? -1 : 1) * fromDataPoint.value);
    }
    if (isSingleSeries && toDataPoint.isNegative && toDataPoint.startPosition + ((toDataPoint.isVariance ? -1 : 1) * toDataPoint.value) < 0) {
        endValue = toDataPoint.startPosition + ((toDataPoint.isVariance ? -1 : 1) * toDataPoint.value);
    }

    if (settings.differenceHighlightFromTo === DifferenceHighlightFromTo.MinToMax && fromDataPoint.isVariance) {
        startValue -= fromDataPoint.value;
    }

    const dhY1 = y + yScale(endValue);
    const dhY2 = y + yScale(startValue);

    if (settings.hasAxisBreak) {
        startValue += axisBreakValue;
        endValue += axisBreakValue;
    }

    return {
        dhX1: xScale(toDataPoint.category),
        dhX2: xScale(fromDataPoint.category),
        dhY1: dhY1,
        dhY2: dhY2,
        startValue: startValue,
        endValue: endValue
    };
}

function addOverlayBar(reportArea: d3.Selection<SVGElement, any, any, any>, dataPoints: DataPoint[], xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, max: number, topMargin: number, y: number, gradient: string) {
    let barOver = reportArea
        .selectAll<SVGRectElement, DataPoint>(null)
        .data(dataPoints);

    const rect = barOver.enter()
        .append(RECT)
        .classed(BAR, true);
    barOver = barOver.merge(rect)
        .attr(WIDTH, xScale.bandwidth())
        .attr(HEIGHT, d => Math.abs(yScale(max - d.value) - topMargin))
        .attr(Y, d => y + yScale(d.startPosition))
        .attr(X, d => xScale(d.category));
    barOver.attr(FILL, `url(#${gradient})`);
    barOver.attr(POINTER_EVENTS, NONE);
}

export function addWaterfallFixedColumnsMouseHandlers(fixedColumnBars: d3.Selection<any, DataPoint, any, any>, reportArea: d3.Selection<SVGElement, any, any, any>, settings: ChartSettings, slider: HTMLElement) {
    if (!settings.getRealInteractionSettingValue(settings.allowAxisBreakChange) || Visual.isChartFocusPopupShown) {
        return;
    }
    fixedColumnBars.on(MOUSEOVER, (event) => {
        const e = fixedColumnBars.nodes();
        const i = <number>e.indexOf(<Event>event?.currentTarget);
        const bar = fixedColumnBars.filter((d, j) => { return j === i; });
        bar.classed(CLICKABLE, true);
        settings.shouldPlotVerticalCharts() ? charting.plotVerticalAxisBreakSymbol(reportArea, bar, settings, slider, false)
            : charting.plotAxisBreakSymbol(reportArea, bar, settings, slider, false);
        if (fixedColumnBars && fixedColumnBars[0] && fixedColumnBars[0].length > 1) {
            for (let k = 0; k < fixedColumnBars[0].length; k++) {
                if (k === i) {
                    continue;
                }
                const oppositeBar = fixedColumnBars.filter((d, j) => { return j === k; });
                settings.shouldPlotVerticalCharts() ? charting.plotVerticalAxisBreakSymbol(reportArea, oppositeBar, settings, slider, false)
                    : charting.plotAxisBreakSymbol(reportArea, oppositeBar, settings, slider, false);
            }
        }
        reportArea.selectAll("*." + AXIS_BREAK_SYMBOL).attr(POINTER_EVENTS, NONE);
    });

    fixedColumnBars.on(MOUSEOUT, (event, i) => {
        fixedColumnBars.classed(CLICKABLE, false);
        reportArea.selectAll("*." + AXIS_BREAK_SYMBOL).remove();
    });

    fixedColumnBars.on(CLICK, () => {
        if (settings.proVersionActive()) {
            settings.hasAxisBreak = !settings.hasAxisBreak;
            settings.persistAxisBreak();
            settings.persistDataLabels();
        }
        else {
            const scrollTop = slider.scrollTop;
            const labelBoundingClientRect = (<SVGElement>fixedColumnBars.node()).getBoundingClientRect();
            const rectWidth = labelBoundingClientRect.width,
                rectHeight = labelBoundingClientRect.height,
                rectX = labelBoundingClientRect.left,
                rectY = labelBoundingClientRect.top + scrollTop;
            const yStart = rectY + 0.5 * rectHeight - 25;
            showPopupMessage(Visual.element, rectX + rectWidth, yStart, "Axis break available in Pro version");
        }
    });
}

export function labelDensityFilterWaterfall(point: DataPoint, index: number, array: DataPoint[]): boolean {
    const viewModel = Visual.viewModel;
    const isLastIndex = viewModel.isSingleSeriesViewModel && !viewModel.settings.showGrandTotal ? index === array.length - 1 : index === array.length - 2 || index === array.length - 1;
    const varianceDataPoints = array.filter(d => d.isVariance);
    const getValue: (d: DataPoint) => number = d => d.value * (d.isNegative ? -1 : 1);
    switch (this.labelDensity) {
        case LabelDensity.Full:
        case LabelDensity.Auto:
        case LabelDensity.High:
        case LabelDensity.Medium:
        case LabelDensity.Low:
            return true;
        case LabelDensity.None:
            return false;
        case LabelDensity.Last:
            return isLastIndex;
        case LabelDensity.FirstLast:
            return index === 0 || isLastIndex;
        case LabelDensity.MinMax: {
            const maxPoint = varianceDataPoints.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
            const minPoint = varianceDataPoints.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
            const maxIndex = array.indexOf(maxPoint);
            const minIndex = array.indexOf(minPoint);
            return index === maxIndex || index === minIndex;
        }
        case LabelDensity.FirstLastMinMax: {
            const maxP = varianceDataPoints.reduce((a, b) => getValue(a) > getValue(b) ? a : b);
            const minP = varianceDataPoints.reduce((a, b) => getValue(a) > getValue(b) ? b : a);
            const maxIndx = array.indexOf(maxP);
            const minIndx = array.indexOf(minP);
            return index === 0 || isLastIndex || index === maxIndx || index === minIndx;
        }
    }
}

function getWaterfallLabelProperties(dataPoints: DataPoint[], settings: ChartSettings, labelsFormat: string, varianceLabelsFormat: string, hideUnits: boolean,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, axisBreak: number): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(d => d.value !== null).filter(labelDensityFilterWaterfall, settings);

    const labelProperties = labelsDataPoints.map(d => {
        let labelValue = settings.hasAxisBreak && !d.isVariance ? d.value + axisBreak : d.value;
        const labelFormat = d.isVariance ? varianceLabelsFormat : labelsFormat;
        let labelText = "";
        if (!d.isVariance) {
            labelText = charting.getFormattedDataLabel(labelValue, settings.decimalPlaces, settings.displayUnits, settings.locale,
                settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, false, labelFormat, hideUnits);
        }
        else {
            if (settings.varianceLabel === DifferenceLabel.Relative) {
                const orgValue = d.originalValue !== null ? d.reference + d.value * (d.isNegative ? -1 : 1) : null;
                const relDiffPercent = viewModels.calculateRelativeDifferencePercent(orgValue, d.reference);
                labelValue = relDiffPercent === null ? 0 : (d.isCategoryInverted ? -1 : 1) * relDiffPercent;
                labelText = relDiffPercent === null ? "" : charting.getRelativeVarianceLabel(settings, labelValue, false);
            }
            else {
                const isNegative = d.isNegative !== d.isCategoryInverted;
                labelText = charting.getVarianceDataLabel((isNegative ? -1 : 1) * labelValue, settings.decimalPlaces, settings.displayUnits,
                    settings.locale, settings.showNegativeValuesInParenthesis(), settings.showPercentageInLabel, false, false, labelFormat, hideUnits);
            }
        }

        const plotLabelBelowBar = d.isNegative && Math.abs(yScale(d.isVariance ? d.startPosition - d.value : d.value) - yScale(0)) > ((settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute ? 2 : 1) * settings.labelFontSize + 4);
        const xPos = xScale(d.category) + xRangeBand * 0.5;
        const belowTheBarLabelOffset = settings.labelFontSize + 2;
        const yPos = y + (plotLabelBelowBar ? yScale(d.isVariance ? d.startPosition - d.value : d.value) + belowTheBarLabelOffset :
            yScale(d.startPosition) - (d.isVariance && settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute ? settings.labelFontSize + 6 : 5));

        let alignment = null;
        if (d.isVariance && settings.labelDensity === LabelDensity.FirstLast && !plotLabelBelowBar) {
            const labelWidth = drawing.measureTextWidth(labelText, settings.labelFontSize, getFontFamily(settings.labelFontFamily),
                getFontWeight(settings.labelFontFamily), NORMAL) + 2 * HORIZONTAL_LABEL_PADDING;
            if (labelWidth > xRangeBand * 2.5) {
                labelText = "";
            }
            else if (labelWidth > xRangeBand * 1.4) {
                alignment = END;
            }
        }
        return charting.getLabelProperty(labelText, xPos, yPos, d.isVariance ? null : true, labelValue, d, settings.labelFontSize, settings.labelFontFamily, d.isVariance, null, null, alignment);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}

function getAdditionalLabelProperties(dataPoints: DataPoint[], settings: ChartSettings,
    xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number): LabelProperties[] {
    const xRangeBand = xScale.bandwidth();
    const labelsDataPoints = dataPoints.filter(d => d.value !== null)
        .filter(labelDensityFilterWaterfall, settings)
        .filter(d => d.isVariance);
    const labelProperties = labelsDataPoints.map(d => {
        const orgValue = d.originalValue !== null ? d.reference + d.value * (d.isNegative ? -1 : 1) : null;
        const relDiffPercent = viewModels.calculateRelativeDifferencePercent(orgValue, d.reference);
        const labelValue = relDiffPercent === null ? 0 : (d.isCategoryInverted ? -1 : 1) * relDiffPercent;
        let labelText = relDiffPercent === null ? "" : charting.getRelativeVarianceLabel(settings, labelValue, true);
        const xPos = xScale(d.category) + xRangeBand * 0.5;
        const plotLabelBelowBar = d.isNegative && Math.abs(yScale(d.isVariance ? d.startPosition - d.value : d.value) - yScale(0)) > ((settings.varianceLabel === DifferenceLabel.RelativeAndAbsolute ? 2 : 1) * settings.labelFontSize + 4);
        const yPos = y + (plotLabelBelowBar ? yScale(d.startPosition - d.value) + 2 * settings.labelFontSize + 3 : yScale(d.startPosition) - 5);

        let alignment = null;
        if (d.isVariance && settings.labelDensity === LabelDensity.FirstLast && !plotLabelBelowBar) {
            const labelWidth = drawing.measureTextWidth(labelText, settings.labelFontSize, getFontFamily(settings.labelFontFamily),
                getFontWeight(settings.labelFontFamily), NORMAL) + 2 * HORIZONTAL_LABEL_PADDING;
            if (labelWidth > xRangeBand * 2.5) {
                labelText = "";
            }
            else if (labelWidth > xRangeBand * 1.4) {
                alignment = END;
            }
        }
        return charting.getLabelProperty(labelText, xPos, yPos, null, labelValue, d, settings.labelFontSize, settings.labelFontFamily, d.isVariance, null, null, alignment);
    });
    return charting.checkForOverlappedLabels(labelProperties, settings.labelDensity, xRangeBand);
}


function plotCommentMarkers(container: d3.Selection<SVGElement, any, any, any>, commentDataPoints: DataPoint[], xScale: d3.ScaleBand<string>, yScale: d3.ScaleLinear<number, number>, y: number, settings: ChartSettings, labelsProperties: LabelProperties[]) {
    const xScaleBandWidth = xScale.bandwidth();
    const markerAttrs = charting.getCommentMarkerAttributes(xScaleBandWidth);
    const getMarkerHorizontalPosition = (d: DataPoint): number => xScale(d.category) + xScale.bandwidth() / 2;
    const getMarkerVerticalPosition = (d: DataPoint): number => {
        const yPositions = {
            outsideBarOppositeOfValueLabel: y + (d.isNegative ? yScale(d.startPosition) - (markerAttrs.radius + markerAttrs.margin) : yScale(d.startPosition - d.value) + (markerAttrs.radius + markerAttrs.margin)),
            insideBarAboveAxis: y + yScale(0) - (markerAttrs.radius + markerAttrs.margin),
            outsideBarAboveValueLabel: y + (yScale(d.startPosition) - (markerAttrs.radius + markerAttrs.margin) - (labelsProperties.find(x => x.category === d.category)?.height ?? 0))
        };
        const yPositionDefault = yPositions.outsideBarOppositeOfValueLabel;

        if (d.isCategoryResult) {
            return yPositions.insideBarAboveAxis;
        }

        // detecting possible overlapping of the category label
        const categoryLabels = container.selectAll(`${TEXT}.${CATEGORY}.${HIGHLIGHTABLE}`);
        let categoryLabelsNodes = <SVGTextElement[]>categoryLabels.nodes();
        categoryLabelsNodes = categoryLabelsNodes.filter(label => (<any>label).__data__.category === d.category);

        if (categoryLabelsNodes) {
            const categoryLabelNode = categoryLabelsNodes[0];
            if ((<any>categoryLabelNode).__data__.category === d.category) {
                const labelBB = categoryLabelNode.getBoundingClientRect();
                if ((yPositionDefault + markerAttrs.radius / 2) > labelBB.y) {
                    return yPositions.outsideBarAboveValueLabel;
                }
            }
        }
        return yPositionDefault;
    };
    charting.addCommentMarkers(container, commentDataPoints, getMarkerHorizontalPosition, getMarkerVerticalPosition, markerAttrs.radius, markerAttrs.fontSize, settings);
}
